'use client';

import setup from 'lib/http/setup';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import Cookies from '@mentimeter/cookies';
import { createDscFromThemeSetting } from '@mentimeter/ragnar-ui';
import { FelaProvider } from '../FelaProvider';

setup();

export function Providers({ children }: { children: React.ReactNode }) {
  const uiTheme = Cookies.get('ui-theme');
  const { theme } = createDscFromThemeSetting(uiTheme);

  const breakpoints = theme.breakpoints.map((b) => stripAllNonNumericChars(b));
  return (
    <DeviceProvider breakpoints={breakpoints}>
      <ThemeProvider theme={theme}>
        <FelaProvider>{children}</FelaProvider>
      </ThemeProvider>
    </DeviceProvider>
  );
}
